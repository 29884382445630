<template>
  <div class="company-enquiry">
    <!-- <p style="margin-left:20px; font-weight: bold;">
        <label>Enquiry List</label>
    </p> -->
    <div class="title">
      <h3>{{ $t(`company.menu.enquiry`) }}</h3>

    </div>
    <div class="table">
      <el-collapse ref="filter" v-model="activeNames" @change="handleCollapseChange">
        <el-collapse-item name="1">
          <template #title>
            <el-icon class="icon">
              <i class="bi bi-funnel-fill" />
            </el-icon>
            <h3>{{ $t('company.enquiry.filter') }}</h3>
          </template>
          <div class="search-filter">
            <el-form>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item :label="th('name')">
                    <el-input :maxlength="25" v-model="searchObj.name" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="th('mobile')">
                    <el-input type="tel" :maxlength="8" v-model="searchObj.mobile" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="th('email')">
                    <el-input type="email" :maxlength="40" v-model="searchObj.email" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item :label="th('status')">
                    <el-select v-model="searchObj.status" :placeholder="$i18n.t('common.select.placeholder')" clearable>
                      <el-option v-for="(val, index) in tbm('status')" :key="index" :value="index" :label="val" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="th('created.start')">
                    <el-date-picker v-model="searchObj.createDateStart" type="datetime"
                      :placeholder="$i18n.t('common.datetimepicker.placeholder')" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="th('created.end')" v-if="searchObj.createDateStart">
                    <el-date-picker v-model="searchObj.createDateEnd" type="datetime"
                      :default-time="new Date(2000, 1, 1, 23, 59, 59)" :placeholder="$i18n.t('common.datetimepicker.placeholder')" />
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="table-footer right">
                <el-button style="margin-right:20px;" type="danger" size="medium" plain @click="clearFilter">
                  <el-icon class="el-icon--left">
                    <i class="bi bi-eraser-fill"></i>
                  </el-icon>
                  <span>{{ $t("common.button.clearFilter") }}</span>
                </el-button>
                <el-button type="primary" @click="search">
                  <el-icon>
                    <i class="bi bi-search"></i>
                  </el-icon>
                  <span>{{ $t("common.button.search") }}</span>
                </el-button>
              </div>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-divider />
      <p v-if="table.list.length > 0">{{ i18nPluralizationL("company.enquiry.recordFound")
      }}</p>
      <el-table :data="table.list.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
        @selection-change="selectionChange" v-loading="table.loading"
        :empty-text="this.$i18n.t('company.enquiry.noData')">
        <el-table-column width="15"></el-table-column>
        <el-table-column :label="th('created.title')" prop="createdDate" min-width="180" sortable>
          <template #default="scope">
            {{ formatDT(scope.row.createdDate, "YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column :label="th('formType')" width="180" sortable :sort-method="handleAppTypeSorting">
          <template #default="scope">
            {{ tb(`formType.${scope.row.type.toString()}`) }}
          </template>
        </el-table-column>
        <el-table-column :label="th('appId')" prop="applicationId" min-width="150" sortable
          :sort-method="handleAppIdSorting"></el-table-column>
        <el-table-column :label="th('name')" prop="name" min-width="260" sortable></el-table-column>
        <el-table-column :label="th('mobile')" prop="mobile" min-width="150" sortable></el-table-column>
        <el-table-column :label="th('email')" prop="email" min-width="200" sortable></el-table-column>
        <el-table-column :label="th('status')" prop="status" min-width="150" sortable>
          <template #default="scope">
            <!-- {{  scope.row.status  }} -->
            {{ tb(`status.${scope.row.status.toString()}`) }}
          </template>
        </el-table-column>
        <el-table-column :label="th('expiry')" prop="expiryDate" min-width="150" sortable>
          <template #default="scope">
            {{ formatDT(scope.row.expiryDate, "YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <!-- <el-table-column :label="th('token')" prop="token" min-width="150"></el-table-column> -->
        <el-table-column fixed="right" width="160">
          <template #default="scope">
            <div style="text-align:right;">
              <table>
                <tbody>
                  <tr style="background-color: transparent;">
                    <td width="33%">
                      <el-tooltip v-if="scope.row.status == 0" :content="$t('common.button.edit')" placement="top">
                        <el-button type="" circle plain @click="rowEdit($event, scope.$index, scope.row)">
                          <el-icon class="el-icon">
                            <i class="bi bi-pencil-square"></i>
                          </el-icon>
                        </el-button>
                      </el-tooltip>
                    </td>
                    <td width="33%">
                      <el-tooltip v-if="scope.row.status <= 1" :content="$t('common.button.resend')" placement="top">
                        <el-button type="primary" circle plain @click="rowResend($event, scope.$index, scope.row)">
                          <el-icon class="el-icon">
                            <i class="bi bi-envelope" />
                          </el-icon>
                        </el-button>
                      </el-tooltip>
                    </td>
                    <td width="33%">
                      <el-tooltip v-if="scope.row.status == 0" :content="$t('common.button.cancelApp')" placement="top">
                        <el-button type="danger" circle plain @click="rowCancel($event, scope.$index, scope.row)">
                          <el-icon class="el-icon">
                            <i class="bi bi-ban" />
                          </el-icon>
                        </el-button>
                      </el-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center; margin-top: 10px;">
        <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="table.list.length"
          :page-size="pagesize"
          @current-change="current_change">
        </el-pagination> -->
        <el-config-provider :locale="getLang">
          <el-pagination class="pagination" v-model:currentPage="currentPage" v-model:page-size="pagesize"
            :page-sizes="[25, 50, 100]" layout="sizes, prev, pager, next" :total="table.list.length"
            @size-change="handleSizeChange" @current-change="current_change" />
        </el-config-provider>
      </div>
    </div>
    <el-dialog @close="v$.recordDialog.staff.$reset()" v-model="recordDialog.display" custom-class="staff-dialog">
      <template #title>
        <p class="staff-dialog-header">{{ this.recordDialog.title }}</p>
        <p class="staff-dialog-header">{{ this.recordDialog.text }}</p>
      </template>
      <template #>
        <el-form class="add-staff-form" v-loading="recordDialog.loading">
          <el-form-item :label="th('name')" :class="isInvalid(v$.recordDialog.staff.name.$error)">
            <el-input :maxlength="25" v-model="recordDialog.staff.name" placeholder
              @focus="v$.recordDialog.staff.name.$reset()"
              @blur="triggerValidate($event, v$.recordDialog.staff.name, 'upper')" />
            <div class="invalid-msg" v-if="v$.recordDialog.staff.name.$error">{{
              v$.recordDialog.staff.name.$errors[0].$message }}</div>
          </el-form-item>
          <el-form-item :label="th('mobile')" :class="isInvalid(v$.recordDialog.staff.mobile.$error)">
            <el-input :maxlength="8" v-model="recordDialog.staff.mobile" placeholder type="tel"
              @focus="v$.recordDialog.staff.mobile.$reset()"
              @blur="triggerValidate($event, v$.recordDialog.staff.mobile)" />
            <div class="invalid-msg" v-if="v$.recordDialog.staff.mobile.$error">{{
              v$.recordDialog.staff.mobile.$errors[0].$message }}</div>
          </el-form-item>
          <el-form-item :label="th('email')" :class="isInvalid(v$.recordDialog.staff.email.$error)">
            <el-input :maxlength="40" v-model="recordDialog.staff.email" placeholder type="email"
              @focus="v$.recordDialog.staff.email.$reset()"
              @blur="triggerValidate($event, v$.recordDialog.staff.email, 'lower')" />
            <div class="invalid-msg" v-if="v$.recordDialog.staff.email.$error">{{
              v$.recordDialog.staff.email.$errors[0].$message }}</div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button type="default" @click="dialogClose(recordDialog)">
          <span>{{ $t("common.button.cancel") }}</span>
        </el-button>
        <el-button type="primary" @click="dialogConfirm">
          <span>{{ $t("common.button.confirm") }}</span>
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
.company-enquiry>>>.add-staff-form .el-form-item {
  display: block;
  margin: 0;
}

.toolbar,
.title {
  margin: 10px 20px;
}

.icon {
  margin-right: 10px;
  font-size: 1em;
}

.toolbar .right,
.table-footer.right {
  text-align: right;
}

.table {
  margin: 20px 20px 20px 20px;
}

.company-enquiry>>>.el-table {
  width: 100%;
}

/* .company-enquiry >>> .el-table .cell{
  line-height: 48px;
} */

.company-enquiry>>>.el-table__append-wrapper {
  text-align: right;
  padding: 10px;
}

.company-enquiry>>>.el-form-item__label {
  width: 80px;
}

.table>>>.el-collapse {
  border: none;
}

.table>>>.el-collapse-item__header {
  background-color: white;
  border-bottom: 1px solid var(--el-border-color-base);
}

.table>>>.el-collapse-item__header.is-active {
  background-color: #b44357;
}

.search-filter {
  padding: 10px 20px 0px 20px;
}

.search-filter .el-form-item {
  display: block;
}

.company-enquiry>>>.el-icon.el-collapse-item__arrow {
  transform: none;
}

.company-enquiry>>>.el-icon.el-collapse-item__arrow.is-active {
  transform: rotate(90deg);
}

.company-enquiry>>>.staff-dialog .el-dialog__body {
  padding: 10px 20px;
}

.company-enquiry>>>.staff-dialog-header {
  margin: 5px 0px 0px 0px;
  font-weight: bold;
}

.company-enquiry>>>.add-staff-form .el-form-item {
  display: block;
  margin: 0;
}

.pagination>>>.el-pager>li,
.pagination>>>.btn-prev,
.pagination>>>.btn-next {
  margin: 0px 1px;
}
</style>

<script>

import moment from "moment";
import registration from "../../http/apis/company/registration.js";
import { parseJWT, handleCompanyException, i18nPluralization } from "../../libs/common.js";
import { ConfirmDialog, SuccessMessage } from "../../plugins/message.js";
import TableViewVue from "./TableView.vue";
import useVuelidate from '@vuelidate/core'
import * as validators from '@/libs/i18n-validators'
import * as common from "@/libs/common"
import eng from "element-plus/lib/locale/lang/en";
import chi from "element-plus/lib/locale/lang/zh-tw"
import constants from "@/libs/constants";

export default {
  name: "CompanyEnquiry",
  extends: TableViewVue,
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true,
        $autoDirty: false
      })
    }
  },
  validations() {
    return {
      recordDialog: {
        staff: {
          name: {
            required: validators.required,
            checkLang: validators.checkLang({ lang: "E", allowNumber: false, allowSpacing: true, allowSymbol: false }),
          },
          email: {
            required: validators.required,
            maxLength: validators.maxLength({
              limit: { num: 40 },
              unit: { num: constants.unit.character }
            }),
            email: validators.email
          },
          mobile: {
            required: validators.required,
            numeric: validators.numeric,
            exactLength: validators.exactLength({
              limit: { num: 8 },
              unit: { num: constants.unit.digit }
            })
          }
        }
      }
    }
  },
  data() {
    return {
      showView: false,
      activeNames: "0",
      table: {
        list: [],
        selectionList: [],
        loading: false
      },
      recordDialog: {
        display: false,
        title: "",
        text: "",
        staff: {
          name: null,
          mobile: null,
          email: null
        },
        originStaff: {},
        loading: false
      },
      pagesize: 25,
      currentPage: 1
    }
  },
  methods: {
    // Table
    handleSizeChange(val) {
      this.pagesize = val;
    },
    selectionChange(selection) {
      this.table.selectionList = selection;
    },
    rowEdit(event, index, row) {
      this.openDialog("Edit Staff", false, row);
      // this.recordDialog.originStaff = row;
      this.targetBlur(event);
    },
    rowResend(event, index, row) {
      this.targetBlur(event);
      let action = "resend"
      let dialogMsgObj = this.$i18n.tm(`company.dialog.${action}Confirmation`)
      ConfirmDialog(dialogMsgObj.title, dialogMsgObj.message,
        () => {
          this.tableLoading(true);
          registration.postRecordResend(this.$store.getters.companyJWT, row.token).then(() => {
            this.handleSuccessAction(action)
          }).catch((err) => {
            handleCompanyException(err)
          }).finally(() => {
            this.loadData();
            this.tableLoading(false)
          })
        });
    },
    rowCancel(event, index, row) {
      let action = "cancel"
      let dialogMsgObj = this.$i18n.tm(`company.dialog.${action}Confirmation`)
      ConfirmDialog(dialogMsgObj.title, dialogMsgObj.message,
        () => {
          this.tableLoading(true);
          registration.postRecordCancel(this.$store.getters.companyJWT, row.token).then(() => {
            this.handleSuccessAction(action)
          }).catch((err) => {
            handleCompanyException(err);
          }).finally(() => {
            this.loadData();
            this.tableLoading(false)
          })
        });
    },
    tableLoading(flag) {
      this.table.loading = flag;
    },
    formatDT(source, format) {
      return moment(source).format(format);
    },
    search() {
      if (this.searchObj.createDateStart && !this.searchObj.createDateEnd) {
        this.searchObj.createDateEnd = new Date(moment(this.searchObj.createDateStart).endOf("day").utc());
      }
      if (!this.searchObj.createDateStart) this.searchObj.createDateEnd = null;
      this.$store.commit("updateSearchObj", this.searchObj);
      this.activeNames = null;
      this.loadData();
    },
    clearFilter() {
      this.$store.commit("clearSearchObj");
    },
    current_change(currentPage) {
      this.currentPage = currentPage;
    },

    // Record Dialog
    openDialog(title, isAdd, staff) {
      let dialog = this.recordDialog;
      dialog.display = true;
      dialog.title = this.$i18n.t("company.dialog.staffDialog.title.edit");
      dialog.text = this.$i18n.t("company.dialog.staffDialog.message");
      dialog.isAdd = isAdd;
      dialog.staff.name = staff.name;
      dialog.staff.mobile = staff.mobile;
      dialog.staff.email = staff.email;
      dialog.staff.token = staff.token;
      Object.assign(dialog.originStaff, dialog.staff);
    },
    async dialogConfirm() {
      let dialog = this.recordDialog;
      this.v$.recordDialog.staff.$reset();
      if (Object.entries(dialog.originStaff).toString() === Object.entries(dialog.staff).toString()) {
        this.dialogClose(dialog);
        return;
      }
      let validateRes = await this.v$.recordDialog.staff.$validate();
      if (validateRes) {
        let action = "edit"
        let dialogMsgObj = this.$i18n.tm(`company.dialog.${action}Confirmation`)
        ConfirmDialog(dialogMsgObj.title, dialogMsgObj.message,
          () => {
            dialog.loading = true;
            registration.postEdit(this.$store.getters.companyJWT, this.v$.recordDialog.staff.$model).then(() => {
              this.handleSuccessAction(action)
              this.dialogClose(dialog);
            }).catch((err) => {
              handleCompanyException(err)
            }).finally(() => {
              this.loadData();
              dialog.loading = false;
            })
          });

      }
    },
    isInvalid(invalid, extraClass = "") {
      return common.isInvalid(invalid, extraClass)
    },
    triggerValidate(event, source, type) {
      common.triggerValidate(event, source, type)
    },

    // common
    targetBlur(event) {
      event.currentTarget.blur();
    },
    dialogClose(dialog) {
      dialog.display = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    loadData() {
      this.tableLoading(true);
      let jwt = this.$store.getters.companyJWT;
      let companyCode = parseJWT(jwt)["co-code"];
      let searchObj = this.$store.getters.searchObj;
      searchObj.companyCode = companyCode;
      registration.getRegistrationEnquiry(jwt, searchObj).then((res) => {
        if (res.status == 200) {
          let data = res.data.sort((obj1, obj2) => {
            return obj2.createdDate - obj1.createdDate
          });
          this.table.list = data;
        }
      }).catch((err) => {
        handleCompanyException(err)
      }).finally(() => {
        this.tableLoading(false);
      })
    },
    handleCollapseChange(val) {
      this.activeNames = (val.length - 1).toString();
    },
    handleSuccessAction(action = "default") {
      SuccessMessage(this.$i18n.t(`company.message.success.${action}`));
    },
    handleAppIdSorting(a, b) {
      if (a.applicationId === null) {
        return -1;
      }

      if (b.applicationId === null) {
        return 1;
      }

      if (a.applicationId === b.applicationId) {
        return 0;
      }
      if (a.applicationId < b.applicationId) return -1;
      if (a.applicationId > b.applicationId) return 1;
    },
    handleAppTypeSorting(a, b) {
      if (a.type < b.type) return 1;
      if (a.type > b.type) return -1;
    },
    i18nPluralizationL(path) {
      return i18nPluralization(this.$i18n.t(path), { num: this.table.list.length }, { num: constants.unit.entry });
    }
    // handleSorting(column, prop, order) {
    //   console.log(column, prop, order)
    // }
  },
  computed: {
    searchObj() {
      return this.$store.getters.searchObj;
    },
    getLang() {
      return this.$i18n.locale == "ch" ? chi : eng
    }
  },
  created() {
    this.loadData();
  },
  beforeRouteUpdate() {
    this.loadData();
  },
  // watch: {
  //   searchObj: {
  //     handler(val) {
  //       this.$store.commit("updateSearchObj", val);
  //     },
  //     deep: true,
  //   },
  // },
}
</script>

      